<template>
  <div class="home">
    <card type="primary">
      <div class="row" id="root">
        <div class="col">
          <h4 class="card-title">Saved Programme Inquiries</h4>
          <br />
          <span>Please find a list of your submitted inquiries below, you may re-open and continue working on one or choose to delete it.</span>
        </div>
        <div class="col text-right">
          <button
            class="btn btn-sm btn-outline-secondary"
            @click="reloadProgrammes"
          >
            <i class="fa fa-sync"></i>&nbsp;Refresh Programmes
          </button>
        </div>
      </div>
      <br />
      <div v-if="$store.getters.isLoggedIn" class="row">
        <div class="col">
          <span v-if="loadingProgrammes && programmeList.length === 0">
            <i class="fa fa-sync fa-spin"></i>&nbsp;Loading Programme
            Inquiries...
          </span>
          <div v-if="!loadingProgrammes || prospectProgrammes.length > 0">
            <h5 class="text-muted">Prospects</h5>
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Policy Class Type</th>
                  <th>Countries</th>
                  <th>Last Step</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-if="!loadingProgrammes && prospectProgrammes.length === 0"
                >
                  <td colspan="6">
                    <span class="text-muted"
                      >You currently have no prospect Programme Inquiries</span
                    >
                    <br />
                    <button
                      class="btn btn-primary my-1"
                      @click="$router.push({ name: 'Country Select' })"
                    >
                      <i class="fa fa-globe"></i>&nbsp; Start Programme Inquiry
                    </button>
                  </td>
                </tr>
                <tr v-for="programme in prospectProgrammes" :key="programme.id">
                  <td>
                    <strong>{{ programme.name }}</strong>
                  </td>
                  <td>
                    {{ parseClassTypeFromProgramme(programme) }}
                  </td>
                  <td>
                    <div
                      class="country-list-container"
                      v-html="programme.countries"
                    >
                      {{ programme.countries }}
                    </div>
                  </td>
                  <td>
                    {{ programme.lastStep }}
                  </td>
                  <td>
                    <span
                      class="badge"
                      :class="{
                        'badge-primary':
                          programme.completionStatus.level === 'primary',
                        'badge-success':
                          programme.completionStatus.level === 'sucess',
                        'badge-secondary':
                          programme.completionStatus.level === 'secondary'
                      }"
                      >{{ programme.completionStatus.name }}</span
                    >
                  </td>
                  <td>
                    <span v-if="loadingStateId === programme.id">
                      <i class="fa fa-sync fa-spin"></i>&nbsp;Opening...
                    </span>
                    <button
                      v-else-if="deletingStateId !== programme.id"
                      class="btn btn-success btn-sm mr-1"
                      @click="() => loadProgramme(programme.id)"
                    >
                      <i class="fa fa-folder-open"></i>&nbsp;Open
                    </button>
                    <span v-if="deletingStateId === programme.id">
                      <i class="fa fa-sync fa-spin"></i>&nbsp;Deleting
                    </span>
                    <button
                      v-else-if="loadingStateId !== programme.id"
                      class="btn btn-danger btn-sm btn-fill"
                      @click="() => deleteProgramme(programme.id)"
                      v-b-tooltip.hover.top="{
                        title: 'Delete this Programme Inquiry',
                        variant: 'danger'
                      }"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div v-if="!loadingProgrammes && submittedProgrammes.length > 0">
            <h5 class="text-muted">Submitted</h5>
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Policy Class Type</th>
                  <th>Countries</th>
                  <th>Last Step</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="programme in submittedProgrammes"
                  :key="programme.id"
                >
                  <td>
                    <strong>{{ programme.name }}</strong>
                  </td>
                  <td>
                    {{ parseClassTypeFromProgramme(programme) }}
                  </td>
                  <td>
                    <div
                      class="country-list-container"
                      v-html="programme.countries"
                    >
                      {{ programme.countries }}
                    </div>
                  </td>
                  <td>
                    {{ programme.lastStep }}
                  </td>
                  <td>
                    <span
                      class="badge"
                      :class="{
                        'badge-primary':
                          programme.completionStatus.level === 'primary',
                        'badge-success':
                          programme.completionStatus.level === 'sucess',
                        'badge-secondary':
                          programme.completionStatus.level === 'secondary'
                      }"
                      >{{ programme.completionStatus.name }}</span
                    >
                  </td>
                  <td>
                    <span v-if="loadingStateId === programme.id">
                      <i class="fa fa-sync fa-spin"></i>&nbsp;Opening...
                    </span>
                    <button
                      v-else-if="deletingStateId !== programme.id"
                      class="btn btn-success btn-sm"
                      @click="() => loadProgramme(programme.id)"
                    >
                      <i class="fa fa-folder-open"></i>&nbsp;Open
                    </button>
                    &nbsp;
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-else class="row">
        <div class="col">
          <p>Log in to see your saved Programme Inquiries</p>
          <login-modal @logged-in="reloadProgrammes"></login-modal>
          <button class="btn btn-primary" v-b-modal.modal-login>Login</button>
        </div>
      </div>
    </card>
  </div>
</template>

<style scoped>
div.programme-link-container {
  margin: 5px 10px;
  padding: 10px 0 10px 10px;
  border-bottom: 2px solid #ddd;
}
div.programme-link-container:hover {
  background-color: #eee;
}

.country-list-container {
  max-height: 75px;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.badge {
  padding: 3px 5px;
  font-size: 0.78em;
}
</style>

<script>
import axios from "axios";
import Card from "../components/Cards/Card";
import LoginModal from "../components/LoginModal";

export default {
  name: "Home",
  components: {
    Card,
    LoginModal
  },
  data: () => {
    return {
      message: "This is the message",
      loadingProgrammes: true,
      loadingStateId: 0,
      deletingStateId: 0,
      programmeList: []
    };
  },
  computed: {
    prospectProgrammes() {
      return this.programmeList.filter(
        project => project.completionStatus.name === "Prospect"
      );
    },
    submittedProgrammes() {
      return this.programmeList.filter(
        project => project.completionStatus.name !== "Prospect"
      );
    }
  },
  methods: {
    async reloadProgrammes() {
      this.programmeList = [];
      await this.getProgrammeList();
    },
    async getProgrammeList() {
      const url = process.env.VUE_APP_INQUIRY_API_ENDPOINT + "/progress/list";

      // this.programmeList = [];
      this.loadingProgrammes = true;
      try {
        let { data } = await axios.get(url, {
          withCredentials: true
        });
        this.programmeList = data.programmes;
        this.loadingProgrammes = false;
      } catch (err) {
        console.error(err);
      }
    },
    async loadProgramme(id) {
      const url = process.env.VUE_APP_INQUIRY_API_ENDPOINT + "/progress/" + id;
      this.loadingStateId = id;
      try {
        let { data } = await axios.get(url, {
          withCredentials: true
        });
        if (data.state) {
          await this.$store.dispatch("loadState", data);
          let lastUrlName = data.state.lastUrlName;
          if (
            data.state.localPolicies.completionStatus &&
            data.state.localPolicies.completionStatus !== "prospect"
          ) {
            this.$toasted.info(
              "This Programme has already been submitted - it is now read-only"
            );
          }
          if (lastUrlName) {
            await this.$router.push({ name: lastUrlName });
          } else {
            await this.$router.push({ name: "Country Select" });
          }
          this.loadingStateId = 0;
        }
      } catch (err) {
        console.error(err);
      }
    },
    async deleteProgramme(id) {
      const url = process.env.VUE_APP_INQUIRY_API_ENDPOINT + "/progress/" + id;

      this.deletingStateId = id;
      try {
        let { data } = await axios.delete(url, {
          withCredentials: true
        });
        this.programmeList = data.programmes;
        this.deletingStateId = 0;
        this.$toasted.success("Programme successfully deleted");
      } catch (err) {
        console.error(err);
      }
    },
    parseClassTypeFromProgramme(programme) {
      const classType = programme.classType;
      if (typeof classType === 'string') {
        return classType;
      }
      return classType.policy_class_type_caption;
    },
  },
  mounted() {
    const context = this;
    const unwatch = this.$store.watch(
      state => state.user.loggedIn,
      (newValue, oldValue) => {
        if (newValue && !oldValue) {
          context.reloadProgrammes();
          unwatch();
        }
      }
    );
    if (this.$store.getters.isLoggedIn) {
      this.getProgrammeList();
    }
  }
};
</script>
